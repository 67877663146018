<script>
  import { page } from "$app/stores";
  import { links } from "$lib/data";

  $: activeUrl = $page.url.pathname;
</script>


<div class="Menu">
  <ul>
    {#each links as { text, url }}
      <li><a class:is-active={url === activeUrl} href={url}>{text}</a></li>
    {/each}
  </ul>
</div>


<style lang="scss">
  .Menu {
    display:         flex;
    align-items:     center;
    justify-content: space-between;

    ul {
      list-style-type: none;
    }

    li {
      display: inline-block;
      padding-right: 5.063rem;

      &:last-child {
        padding-right: 2.938rem;
      }
    }

    li a {
      color: $text_main;
      font-size: 1.063rem;
      font-family: $font_reg;
      transition: color 0.3s;

      &.is-active {
        color: $text-hl;
      }
    }

    @include tablet_and_mobile {
      display: none;
    }
  }
</style>
