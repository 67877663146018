<script lang="ts">
import type { Link, WebsiteSettings } from "$types/custom";
import { beforeNavigate }  from "$app/navigation";

import Nav             from "$lib/components/Nav.svelte";
import Logo            from "$lib/components/Logo.svelte";
import Menu            from "$lib/components/Menu.svelte";
import MobileNav       from "$lib/components/MobileNav.svelte";
import MobileNavButton from "$lib/components/MobileNavButton.svelte";

export let settings:WebsiteSettings;
export let links:Link[];

let open = false;
const menuToggle = () => open = !open;
beforeNavigate(() => open = false);
</script>


<header>
  <Nav>
    <Logo />
    <Menu />
    <MobileNavButton {open} on:toggle={menuToggle} />
  </Nav>

  <MobileNav {open} {settings} {links} />
</header>


<style lang="scss">
  header {
    position:   fixed;
    top:        0;
    width:      100%;
    height:     5.75rem;
    color:      white;
    z-index:    10;
    transition: 0.2s transform;
    background:  linear-gradient(to top, rgba(0,9,26,0) 0%, rgba(0,9,26,1) 100%);
    padding-top: $spacing_nav;

    @include tablet_and_mobile {
      padding-top: 1.1em;
    }

    @media only screen and (max-width:47.5em) {
      height: auto;
    }

    :global(.Nav)       { position: relative; z-index: 12; }
    :global(.MobileNav) { position: absolute; z-index: 11; }
  }
</style>
